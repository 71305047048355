import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { titleTemplate } from '@/helpers/meta/title-template';

@Component
export default class KnowledgeFaq extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.faq.title').toString()),
    };
  }
}
